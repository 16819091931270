import React from 'react'
import { Container } from 'react-bootstrap'
import DocumentMeta from 'react-document-meta'
import { Link } from 'react-router-dom'
import { varcitylogo } from '../assets'
import LightGreenVarcitybanner from '../components/varcityBanner/LightGreenVarcitybanner'

const ContactUs = () => {
    const meta = {
        title: 'Contact Varcity',
        description: ' Great traders are made. Connect with us to learn more about Varcity, the Varcity Trading Index (VTI) and our real-time trading simulator.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'contact Varcity, Varcity product feedback, Varcity support, Varcity public relations, Varcity media, Varcity advertising'
            }
        }
    };
    return (
        <>
            < DocumentMeta {...meta} />
            <Container>
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <h1 className='greyheaderHeading'>Get in touch</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 mt-5 mb-lg-5">
                        <h2 className='fs-24 fw-600 greyclr'>
                            Product Feedback <br />
                            and Fresh Ideas
                        </h2>
                        <div className='lightgreenBorder mt-3'></div>

                        <p className='fs-16 fw-300 greyclr mt-3'>Varcity has currently released V1.0 and planning new features and functionality for V1.1 coming soon.  What additional features do you want us to add?  Do you see a feature that needs improvement?  We want to hear from you!  Please:</p>

                        <ul className='mt-3 varcityNetworkPoints'>
                            <li> Drop us a line at <span className='fw-500'> <b>feedback@varcity.io</b> </span> </li>
                            <li> Message us on <span className='fw-500' style={{ borderBottom: "1px solid #0A4550" }}> <a href="https://www.instagram.com/varcity.io" className='greyclr'> <b>Instagram</b></a></span></li>
                        </ul>
                    </div>
                   {/* <div className="col-md-4 mt-5 mb-lg-5">
                        <h2 className='fs-24 fw-600 greyclr'>
                            Advertising
                        </h2>
                        <div className='lightgreenBorder mt-3'></div>

                        <p className='fs-16 fw-300 greyclr mt-3'>Want to reach retail investors?  We offer a number of native and display advertising solutions including: </p>

                        <ul className='mt-3 varcityNetworkPoints'>
                            <li>Badge sponsorship</li>
                            <li>Sponsored educational content </li>
                            <li>In-app display advertising</li>
                            <li>Email advertising</li>
                        </ul>

                        <p className='fs-16 fw-300 greyclr mt-3'>Please email <span className='fw-500'> <b>advertising@varcity.io</b></span> for an advertising package.</p>


                    </div>
                    <div className="col-md-4 mt-5 mb-lg-5">
                        <h2 className='fs-24 fw-600 greyclr'>
                            Platform Licensing
                        </h2>
                        <div className='lightgreenBorder mt-3'></div>

                        <p className='fs-16 fw-300 greyclr mt-3'>
                            Contact us at <span className='fw-500'><b>licensing@varcity.io</b></span> to bring Varcity’s enterprise-grade, fully automated trading simulator to colleges and universities.
                        </p>
                        <p className='fs-16 fw-300 greyclr mt-3'>

                            Varcity also offers bespoke trading platfrom solutions for institutions, brokerages and other financial services.
                        </p>
                    </div>*/}

                    <div className="col-md-4 mt-5">
                        <h2 className='fs-24 fw-600 greyclr'>
                            Technical Support
                        </h2>
                        <div className='lightgreenBorder mt-3'></div>

                        <p className='fs-16 fw-300 greyclr mt-3'>One of our exceptional Customer Success representatives will be happy to assist you.</p>

                        <ul className='mt-3 varcityNetworkPoints'>
                            <li> Email us at <span className='fw-500' > <b>support@varcity.io</b> </span></li>
                            <li> Message us on <span className='fw-500' style={{ borderBottom: "1px solid #0A4550" }}> <a href="https://www.instagram.com/varcity.io" className='greyclr'> <b>Instagram</b></a> </span></li>
                        </ul>
                    </div>
                    {/*<div className="col-md-4 mt-5">
                        <h2 className='fs-24 fw-600 greyclr'>
                            Affiliates
                        </h2>
                        <div className='lightgreenBorder mt-3'></div>

                        <p className='fs-16 fw-300 greyclr mt-3'>Please connect with us at <span className='fw-500'><b>affiliates@varcity.io</b></span> for more information.</p>
                    </div>*/}
                    <div className="col-md-4 mt-5">
                        <h2 className='fs-24 fw-600 greyclr'>
                            General Inquiries
                        </h2>
                        <div className='lightgreenBorder mt-3'></div>

                        <p className='fs-16 fw-300 greyclr mt-3'>Our business hours are 9:00am - 5:00pm EST. Please: email us at <span className='fw-500'><b>info@varcity.io.</b></span>
                        </p>
                    </div>
                </div>
            </Container>
            <Container fluid>
                <LightGreenVarcitybanner />
            </Container>
        </>
    )
}

export default ContactUs
