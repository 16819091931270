import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { footerLogoAsset, instaLogoAsset, twitterLogoAsset } from '../../assets'

const Footer = () => {
    return (
        <>
            <Container fluid>
                <div className="row justify-content-center" style={{ background: "#0B4550" }}>
                    <div className="col-md-10 mt-5">
                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <img src={footerLogoAsset} alt="" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-4 col-6 mt-5">
                                <h2 className='fs-21 fw-500 lightgreen'>Varcity Inc. </h2>
                                <div className='mt-lg-5 mt-3 mt-md-5'>

                                    <p className='fs-16 lightgreen'>
                                        600 Matheson Blvd W <br />
                                        Unit 5
                                    </p>
                                    <p className='fs-16 lightgreen'>
                                        Mississauga, Ontario <br />
                                        L5R 4C1
                                    </p>
                                    <p className='fs-16 lightgreen mt-3'>
                                        +1 (365) 398-5007
                                    </p>
                                    <p className='fs-16 lightgreen'>
                                        info@varcity.io
                                    </p>

                                </div>
                            </div>
                            <div className="col-md-2 col-6 mt-5">
                                <h2 className='fs-30 fw-500 lightgreen'>Product</h2>
                                <div className='mt-3' style={{ borderBottom: "1.5px solid #C5F9CA" }}></div>
                                <div className='mt-4'>
                                    {/*<Link to="/platform">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Features
                                        </p>
                                    </Link>*/}
                                    <Link to="/pricing">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Pricing
                                        </p>
                                    </Link>
                                    <Link to="/platform">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Vacrity Trading Index<sup className='fs-8 smSup'>TM</sup>
                                        </p>
                                    </Link>
                                    {/*<Link to="/partners">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Partners
                                        </p>
                                    </Link>*/}
                                    <a href="https://sites.google.com/varcity.io/varcitysupport/mobile-app">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Mobile App
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-2 col-6 mt-5">
                                <h2 className='fs-30 fw-500 lightgreen'>Company</h2>
                                <div className='mt-3' style={{ borderBottom: "1.5px solid #C5F9CA" }}></div>
                                <div className='mt-4'>
                                    <Link to="/company">
                                        <p className='fs-16 lightgreen mt-3'>
                                            About
                                        </p>
                                    </Link>
                                    {/*<Link to="/careers">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Careers
                                        </p>
                                    </Link>*/}

                                    {/*<a href="https://sites.google.com/varcity.io/varcitysupport/referral-program">
                                    <p className='fs-16 lightgreen mt-3'>
                                        Referral Program
                                    </p>
                                    </a>*/}

                                    <Link to="/contactUs">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Contact Us
                                        </p>
                                    </Link>
                                    <a href="https://capfii.com/">
                                        <p className='fs-16 lightgreen mt-3'>
                                            CapFi
                                        </p>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-2 col-6 mt-5">
                                <h2 className='fs-30 fw-500 lightgreen'>Support</h2>
                                <div className='mt-3' style={{ borderBottom: "1.5px solid #C5F9CA" }}></div>
                                <div className='mt-4'>
                                    <a href="https://sites.google.com/varcity.io/varcitysupport/getting-started">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Getting Started
                                        </p>
                                    </a>
                                    <a href="https://sites.google.com/varcity.io/varcitysupport/getting-started/dashboard">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Trading Dashboard
                                        </p>
                                    </a>
                                    <a href="https://sites.google.com/varcity.io/varcitysupport/trading-widgets">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Trading Widgets
                                        </p>
                                    </a>
                                    {/*<a href="https://sites.google.com/varcity.io/varcitysupport/varcity-network">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Varcity Network
                                        </p>
                                    </a>*/}
                                </div>
                            </div>
                            <div className="col-md-2 mt-5">
                                <Link to="/termcondition">
                                    <h2 className='fs-30 fw-500 lightgreen'>Policies</h2>
                                </Link>
                                <div className='mt-3' style={{ borderBottom: "1.5px solid #C5F9CA" }}></div>
                                <div className='mt-4'>
                                    <Link to="/termCondition" style={{ textDecoration: "none" }}>
                                        <p className='fs-16 lightgreen mt-3'>
                                            Terms and Conditions
                                        </p>
                                    </Link>
                                    <Link to="/privacyPolicy" style={{ textDecoration: "none" }}>
                                        <p className='fs-16 lightgreen mt-3'>
                                            Privacy Policy
                                        </p>
                                    </Link>
                                    <Link to="/codeofconduct">
                                        <p className='fs-16 lightgreen mt-3'>
                                            Code of Conduct
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-5 mt-4">
                            <div className="col-md-12 mt-5">
                                <p className='fs-15 lightgreen pb-3' style={{ borderBottom: "1.5px solid #C5F9CA" }}>By using this website, you agree to comply with all applicable securities laws. You agree not to use this website in any manner to provide or purport to provide investment advice.</p>
                            </div>
                            <div className="col-6 mt-3">
                                <p className='fs-10 lightgreen'>© Varcity Inc. 2022</p>
                                <p className='fs-10 lightgreen'>TM Trademark pending.</p>
                            </div>
                            <div className="col-6 mt-3">
                                <div className='d-flex gap-4 justify-content-end align-items-center'>
                                    <p className='fs-14 lightgreen'>Follow us:</p>
                                    <div className='d-flex gap-3'>
                                        <a href="https://www.instagram.com/varcity.io">
                                            <img src={instaLogoAsset} width="33px" height="33px" alt="" />
                                        </a>
                                        {/*<a href="https://twitter.com/varcity_io">
                                            <img src={twitterLogoAsset} width="33px" height="33px" alt="" />
                                        </a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Footer
