import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import DocumentMeta from 'react-document-meta'
import { Link } from 'react-router-dom'
import { affiliateAsset, blankImg, checkmarkAsset, customerSuccessAsset, forwardArrowAsset, home2Asset, home4Asset, knowledgeBaseAsset, varcitydarklogo } from '../assets'

const Pricing = () => {
  const meta = {
    title: 'Varcity Pricing for Basic and Pro Accounts',
    description: ' Compare Varcity subscriptions and pricing. Sign up for a free Basic account or a Pro 7-day free trial.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'Varcity pricing plans, Varcity subscriptions, how much does Varcity cost, Varcity Pro, Varcity Basic, is Varcity free'
      }
    }
  };
  return (
    <>
      < DocumentMeta {...meta} />
      <div className="container-fluid">
        <div className="row pt-5" style={{ background: "#0A4550" }}>
          <div className="col-md-12">
            <div className="row mt-5 justify-content-center">
              <div className="col-md-5">
                <h1 className='headerHeading'>
                  Subscription Plans & Pricing
                </h1>
              </div>
              <div className="col-md-5 ps-lg-5 mt-4 mt-lg-0 mt-md-0">
                <p className='fs-20 fw-400 lightgreen sm-text-center'>
                  Varcity is a trading simulator with real-time market data that helps users develop great trading skills through forward testing, quantitative performance metrics, and rankings.

                </p>

                <p className='fs-20 fw-400 lightgreen sm-text-center'>
                  Built on the fundamental belief that great traders are made and that anyone can learn great trading skills regardless of background, formal education or financial limitations, use Varcity for free with our Basic account or upgrade to a Pro subscription with extra features.
                </p>
              </div>
            </div>

            <div className="row px-sm-3 justify-content-center" style={{ position: "relative", top: "90px" }}>
              <div className="col-lg-5 col-md-6 mt-sm-5 ps-4 pe-4">
                <div className='bg-white br-14 ps-lg-5 pe-lg-5 ps-md-4 pe-md-4 ps-2 pe-2 pt-3 pb-3 h-100' style={{ boxShadow: " 11px 11px 25px rgba(0, 0, 0, 0.25)" }}>
                  <div className="d-flex flex-column h-100">
                    <h1 className='fs-63 fw-500 greyclr'>Basic</h1>
                    <div className="flex-grow-1">
                      <p className='fs-24 fw-600 greyclr mt-2'>Key benefits include:</p>
                      <ul className='basicBenefits mt-3' style={{ paddingBottom: "98px" }}>
                        <li>Fully automated demo trading with real-time Level 1 quotes and Order book</li>
                        <li>Market, limit, stop, stop limit orders as well as conditional take profit and stop loss orders</li>
                        <li>One-cancels-other (OCO) orders</li>
                        <li>Varcity Trading Index trading performance ranking with 10 metrics</li>
                        <li>Trading journal with trade-by-trade analysis</li>
                        <li>Varcity network profile with customizable username</li>
                        <li>Add virtual funds anytime</li>
                      </ul>
                    </div>
                    <div className='d-flex flex-column' style={{ borderTop: "1.5px solid #0A4550" }}>
                      <div className='d-flex'>
                        <span className='fs-16 fw-600 greyclr mt-2'>$</span>
                        <h1 className='fs-63 fw-500 greyclr'>0</h1>
                      </div>
                      <p className='fs-16 fw-600 greyclr'>/month (free!)</p>
                    </div>
                    <a href="https://trading.varcity.io/sign-up-pro" className="d-flex flex-column justify-content-end" style={{ height: '135px' }}>
                      <div className='ps-3 pe-3 pt-2 pb-2 d-flex justify-content-center align-items-center mb-4' style={{ background: "#DAFD5D", borderRadius: "25px" }}>
                        <p className='fs-14 fw-500 greyclr text-center'>Sign up for a free Basic Account</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 mt-sm-5 ps-4 pe-4">
                <div style={{ position: "relative", left: "26px", top: "-40px" }}>
                  <div className='rounded-circle d-flex justify-content-center align-items-center' style={{ background: "#DAFD5D", height: "85px", width: "85px", position: "absolute", right: "0" }}>
                    <img src={varcitydarklogo} width="61.31px" height="44.23px" alt="" />
                  </div>
                </div>
                <div className='bg-white br-14 ps-lg-5 pe-lg-5 ps-md-4 pe-md-4 ps-2 pe-2 pt-3 pb-3 h-100' style={{ boxShadow: " 11px 11px 25px rgba(0, 0, 0, 0.25)" }}>
                  <div className="d-flex flex-column h-100">
                    <h1 className='fs-63 fw-500 greyclr'>Pro</h1>
                    <div className="flex-grow-1">
                      <p className='fs-24 fw-600 greyclr mt-2'>All the features of Basic, plus:</p>
                      <ul className='basicBenefits mt-3' style={{ paddingBottom: "52px" }}>
                        <li>Additional watchlists</li>
                        <li>Additional alerts</li>
                        <li>Downloadable trading journal</li>
                        <li>Unlimited resets</li>
                        <li>Ad-free</li>
                      </ul>
                    </div>
                    <div className='d-flex gap-4 align-items-center' style={{ borderTop: "1.5px solid #0A4550" }}>
                      <div className='d-flex flex-column'>
                        <div className='d-flex'>
                          <span className='fs-16 fw-600 greyclr mt-2'>$</span>
                          <h1 className='fs-63 fw-500 greyclr'>10</h1>
                        </div>
                        <p className='fs-16 fw-600 greyclr'>/month USD</p>
                      </div>
                      <p className='fs-16 fw-300 greyclr'>or</p>
                      <div className='d-flex flex-column'>
                        <div className='d-flex'>
                          <span className='fs-16 fw-600 greyclr mt-2'>$</span>
                          <h1 className='fs-63 fw-500 greyclr'>100</h1>
                        </div>
                        <p className='fs-16 fw-600 greyclr'>/year USD (that’s 2 months free!)</p>
                      </div>
                    </div>
                    <a href="https://trading.varcity.io/sign-up-pro">
                      <div className='ps-3 pe-3 pt-2 pb-2 mt-4  d-flex justify-content-center align-items-center' style={{ background: "#DAFD5D", borderRadius: "25px" }}>
                        <p className='fs-14 fw-500 greyclr text-center'>Sign up for a Monthly  Pro Account @ $10 /month</p>
                      </div>
                    </a>
                    <a href="https://trading.varcity.io/sign-up-pro">
                      <div className='ps-3 pe-3 pt-2 pb-2 mt-3 mb-4 d-flex justify-content-center align-items-center' style={{ background: "#DAFD5D", borderRadius: "25px" }}>
                        <p className='fs-14 fw-500 greyclr text-center'>Sign up for an Annual Pro Account @ $100 /year</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div className="row justify-content-center pt-5" style={{ background: "#F5F5F5" }}>
          <div className="col-md-10">
            <h1 className='fs-42 fw-500 greyclr addingtonFamily mt-5 pt-5'>Compare Features</h1>
          </div>

          <div className="col-md-10 mt-5 mb-3">
            <div className='customScrollbar height500' style={{ overflow: "auto" }}>
              <table className='table compareFeaturesTable'>
                <thead>
                  <tr>
                    <th style={{ fontSize: "24px" }}>Features</th>
                    <th>Basic</th>
                    <th>Pro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3} style={{ background: "#C5F9CA" }}>Demo Trading Tools</td>
                  </tr>
                  <tr>
                    <td>Real-time Level 1 quotes</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Order book</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Charting tools</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Market orders</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Limit orders</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Stop orders</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Stop limit orders</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>If-Then / OCO  orders</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>3 alerts</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>10 alerts</td>
                    <td>--</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>1 watchlist</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>3 watchlists</td>
                    <td>--</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>My portfolio</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Automated Trading Journal</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Download trading journal .csv or PDF</td>
                    <td>--</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Economic calendar</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Dashboard / in-app advertising</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td>--</td>
                  </tr>

                  <tr>
                    <td colSpan={3} style={{ background: "#C5F9CA" }}>Varcity Trading Index   (VTI)</td>
                  </tr>
                  <tr>
                    <td>Real-time VTI rank + evaluation</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>VTI leaderboard</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>VTI badging</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>VTI + 10 additional performance metrics</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Add virtual funds anytime</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Unlimited VTI resets</td>
                    <td>--</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>

                  <tr>
                    <td colSpan={3} style={{ background: "#C5F9CA" }}>Varcity Network</td>
                  </tr>
                  <tr>
                    <td>Varcity Network profile</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Custom username</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Display social links</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>
                  <tr>
                    <td>Trophy case</td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                    <td><img src={checkmarkAsset} alt="" width="46px" height="46px" /></td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <a href="https://trading.varcity.io/sign-up-pro" className='d-flex justify-content-center'>
                        <div className='ps-3 pe-3 pt-2 pb-2 d-flex justify-content-center align-items-center' style={{
                          background: "#DAFD5D", borderRadius: "25px", width: "max-content"
                        }}>
                          <p className='fs-14 fw-500 greyclr'>Sign up for a  free Basic Account</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <a href="https://trading.varcity.io/sign-up-pro" className='d-flex justify-content-center'>
                        <div className='ps-3 pe-3 pt-2 pb-2 d-flex justify-content-center align-items-center' style={{
                          background: "#DAFD5D", borderRadius: "25px", width: "max-content"
                        }}>
                          < p className='fs-14 fw-500 greyclr' > Sign up for a  free 7-day Pro trial</p>
                        </div>
                      </a>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div >

      <Container>
        <div className="row">
          <div className="col-md-12 mt-5 mb-4">
            <h1 className='fs-42 fw-500 greyclr'>Pricing and Payment FAQs</h1>
          </div>

          <div className="col-md-12 mt-5">
            <Accordion defaultActiveKey={['0']} alwaysOpen className='pricingAndPaymentFaq'>
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is your refund policy?</Accordion.Header>
                <Accordion.Body className='pricingAccordianbody'>
                  <div className="row">
                    <div className="col-md-6">
                      <p className='fs-16'> Monthly Pro subscriptions are non-refundable but monthly renewal can be canceled at any time.  Annual Pro subscriptions can be canceled within 14 calendar days of opening your Pro account for a full refund.  More information on cancellation and refunds are in our
                        <Link to="/termCondition">
                          <span className='greyclr' style={{ borderBottom: "1px solid #0B4550" }}>
                            Terms & Conditions.
                          </span>
                        </Link>
                      </p>

                    </div>
                  </div>

                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can I cancel my Pro subscription anytime?</Accordion.Header>
                <Accordion.Body className='pricingAccordianbody'>
                  <p className='fs-16'>
                    Yes, you can cancel a Pro subscription at any time and be moved into a free Basic account.
                  </p>
                  <p className='fs-16'>
                    Monthly Pro subscriptions are non-refundable but  monthly renewal can be cancelled at any time.  Annual Pro subscriptions can be canceled within 14 calendar days of opening your Pro account for a full refund.   For more information, please see our
                    <Link to="/termCondition">
                      <span className='greyclr fw-500' style={{ borderBottom: "1px solid #0B4550" }}><b> Terms & Conditions.</b></span>
                    </Link>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Am I trading real money?</Accordion.Header>
                <Accordion.Body className='pricingAccordianbody'>
                  <p className='fs-16'>
                    When you sign up for a Varcity Pro or Varcity Basic account you are NOT trading real money and you do NOT have a live account. You are demo trading using simulated funds on Varcity’s gamified demo trading platform using real-time market data.  For more information, please see our
                    <Link to="/termCondition">
                      <span className='greyclr fw-500' style={{ borderBottom: "1px solid #0B4550" }}><b> Terms & Conditions.</b></span>
                    </Link>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>

      {/* <div className="container-fluid mt-5">

        <div className="row pt-5 pb-5" style={{ background: "#C5F9CA" }}>
          <div className="col-md-12">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-4 col-lg-5">
                <div className='text-center'>
                  <h1 className='fs-84 fs-100 greyclr italic addingtonFamily bannerHeading' >
                    Have more <br /> questions?
                  </h1>
                </div>
              </div>

              <div className="col-md-8 col-lg-6 w-maxContent">
                <div className="row px-sm-3">
                  <div className="col-md-6 mt-sm-5">
                    <div>
                      <img src={knowledgeBaseAsset} width="100%" height="223px" alt="" />
                      <Link to="/support">
                        <div className='d-flex align-items-center justify-content-between p-4' style={{ background: "#0A4550" }}>
                          <p className='fs-16 fw-500 lightgreen'>Go to  Knowledge Base</p>
                          <img src={forwardArrowAsset} width="44px" height="44px" alt="" />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6 mt-sm-5">
                    <div>
                      <img src={customerSuccessAsset} width="100%" height="223px" alt="" />
                      <Link to="/support">
                        <div className='d-flex align-items-center justify-content-between p-4' style={{ background: "#0A4550" }}>
                          <p className='fs-16 fw-500 lightgreen'>Email our Customer <br /> Success team</p>
                          <img src={forwardArrowAsset} width="44px" height="44px" alt="" />
                        </div>
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>


        </div>


      </div> */}

      <Container>
        <div className="row mt-5 mb-5" >
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>Partnerships</h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
            Varcity partners with brands and companies that help our users achieve their financial goals. We combine our trading simulator, VTI and quantitative performance rankings to create dynamic collaborations and opportunities.
            </p>
            <Link to="/partners">
              <div className='mt-4'>
                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>Varcity’s Referral Program</h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
              Help spread our message of inclusivity, transparency and fairness for all those wanting to learn how to trade, on their path towards becoming a great trader.
            </p>
            <p className='fs-16 fw-500 greyclr mt-3'>
              For a limited time, Varcity's referral program offers 30% lifetime commissions on all Varcity Pro subscriptions purchased through your referrals. Your unique referral code is automatically generated and included in your Welcome email!
            </p>
            <a href="https://trading.varcity.io/sign-up-pro">
              <div className='mt-4'>
                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
              </div>
            </a>
          </div>
        </div>
        {/* <div className="row align-items-center mt-sm-5" style={{ marginTop: "100px" }}>
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>The Varcity Club</h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
              Using data-driven metrics from the Varcity Trading Index , we can crowdsource, evaluate and identify great traders, allocating capital equitably and transparently to the best demo traders on the Varcity Network through our partnership with CapFi Capital.
            </p>
            <Link to="/varcityClub">
              <div className='mt-4'>
                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
              </div>
            </Link>
          </div>
          <div className="col-md-6" >
            <img src={home4Asset} width="100%" height="450px" className='height300' alt="" />
          </div>

        </div> */}
        {/* <div className="row align-items-center mb-5 pb-5 mt-sm-5" style={{ marginTop: "70px" }}>
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>Varcity’s Affiliate Program</h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
              Help spread our message of inclusivity, transparency and fairness for all those wanting to learn how to trade, on their path towards becoming a great trader.
            </p>
            <p className='fs-16 fw-500 greyclr mt-3'>
              Our affiliate program is managed through Rewardful and for a limited time, offers 30% lifetime commissions on all Pro Varcity Account subscriptions purchased through your referrals.
            </p>
            <Link to="/temporarySignUp">
              <div className='mt-4'>
                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
              </div>
            </Link>
          </div>
          <div className="col-md-6" >
            <div>
              <img src={affiliateAsset} width="100%" height="400px" alt="" className='varcityImg mt-4 mt-lg-0 mt-md-0 ' />
            </div>
          </div>

        </div> */}

      </Container>
    </>
  )
}

export default Pricing
